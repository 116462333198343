import Cookies from 'js-cookie';


$('.js-set-participant').on('click', () => {
  Cookies.set('user_role', 'participant');
  Cookies.set('sign_in_origin', window.location.href);
});

$('.js-set-voted').on('click', () => {
  Cookies.set('user_role', 'voted');
  Cookies.set('sign_in_origin', window.location.href);
});
