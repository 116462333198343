import 'lightgallery.js';
import 'lg-zoom.js';
import 'lg-thumbnail.js';
import 'lg-fullscreen.js';
import 'lg-share.js';

import 'lightgallery.js/dist/css/lightgallery.min.css';

lightGallery(document.querySelector('.gallery-participant'), {
    thumbnail: true,
    selector: '.gallery-item-link',
    subHtmlSelectorRelative: true,
});

lightGallery(document.querySelector('.gallery-photos'), {
    thumbnail: true,
    selector: '.gallery-item-link',
    subHtmlSelectorRelative: true,
});
