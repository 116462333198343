/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from 'rails-ujs';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/js/src/tab';

import './direct_uploads';
import './gellery';
import './cookies';
import './tooltips';
// import './photo_uploader';

import './css/bootstrap.scss';
import './css/vars.css';
import './css/index.css';
import '@fortawesome/fontawesome-free/css/all.css';


Rails.start();


